import React from "react";
import isEmpty from "lodash/isEmpty";
import clsx from "clsx";
import { Link } from "gatsby";

// Iterate through all the elements and replacing labels ...
const mappedLabel = (label) => {
  if (label === "&laquo; Previous") {
    return "Previous";
  }

  if (label === "Next &raquo;") {
    return "Next";
  }

  return label;
};

export const Pagination = ({ pagination, onPageChange, location }) => {
  const handlePageChange = (nextPage) => {
    onPageChange(nextPage);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="mt-5">
      <div className="d-flex align-items-center justify-content-center justify-content-md-end">
        <div id="kt_table_users_paginate">
          <ul className="pagination-container pagination-bar">
            {!isEmpty(pagination) &&
              pagination.links
                ?.map((link) => {
                  return { ...link, label: mappedLabel(link.label) };
                })
                ?.map((link) => (
                  <li key={link.label} className={"pagination-item "}>
                    <Link
                      className={clsx("pagination-item", {
                        selected: link.active,
                      })}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (link.label === "Next") {
                          handlePageChange(pagination.next_page);
                        } else if (link.label === "Previous") {
                          handlePageChange(pagination.prev_page);
                        } else if (link.label === "First") {
                          handlePageChange(pagination.first_page);
                        } else if (link.label === "Last") {
                          handlePageChange(pagination.last_page);
                        } else {
                          handlePageChange(link.label);
                        }
                      }}
                      to={`${location.pathname}${link.url}`}
                    >
                      {link.label === "Previous" || link.label === "Next" ? (
                        link.label === "Previous" ? (
                          <li key={"left-arrow"} className={`pagination-item`}>
                            <div className="arrow left" />
                          </li>
                        ) : (
                          <li key={"right-arrow"} className={`pagination-item`}>
                            <div className="arrow right" />
                          </li>
                        )
                      ) : (
                        mappedLabel(link.label)
                      )}
                    </Link>
                  </li>
                ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
