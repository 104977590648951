import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import { isUserLoggedIn } from "../utils/auth";
import { navigate } from "gatsby";
import useGetOrders from "../hooks/orders/useGetOrders";
import Loader from "../components/form/Loader";
import OrderListing from "../components/account-orders/OrderListing";
import Breadcrumbs from "../components/common/breadcrumbs/Breadcrumbs";
import AccountPageNavigation from "../components/account/AccountPageNavigation";
import useGetRepresentativesDetails from "../hooks/representatives-details/useGetRepresentativesDetails";
import { staticPage } from "../../config/staticPage";
import RepresentativeNavigation from "../components/account/RepresentativeNavigation";

const OrdersPage = ({ location }) => {
  const user = isUserLoggedIn();
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const { data, isLoading, isNextPage, pagination, onPageChange } = useGetOrders({
    page,
    limit,
  });
  const { details: representative } = useGetRepresentativesDetails();

  useEffect(() => {
    if (!user) {
      navigate("/login?redirect_to=/orders");
    }
  }, [user]);

  return (
    <>
      {user ? (
        <div className="page-wrapper account-page-wrapper">
          <>
            <div className="container">
              <Breadcrumbs location={location} />
            </div>

            <div className="container">
              <div className="account-content">
                <div className="row flex flex-wrap page-layout">
                  <div className="col page-sidebar">
                    <div className="page-sidebar-inner">
                      <AccountPageNavigation path={"/orders"} />
                      {representative && (
                        <RepresentativeNavigation
                          representative={representative}
                        />
                      )}
                    </div>
                  </div>
                  <div className="col page-content">
                    <div className="page-content-inner">
                      <div className="page-heading-section">
                        <h1 className="page-title">Orders</h1>
                      </div>
                      {isLoading ? (
                        <Loader />
                      ) : (
                        <OrderListing
                          pagination={pagination}
                          onPageChange={onPageChange}
                          setPage={setPage}
                          hasMoreData={false}
                          orders={data}
                          page={page}
                          isNextPage={isNextPage}
                          representative={
                            representative ? representative : null
                          }
                          location={location}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      ) : null}
    </>
  );
};

export const Head = () => {
  let login_nav = staticPage;
  let loginDetails = null;

  for (const obj of login_nav) {
    if (obj.name === "orders") {
      loginDetails = obj;
      break; // Exit the loop once the desired object is found.
    }
  }
  return (
    <>
      <title>{loginDetails?.title}</title>
      <meta name="description" content={loginDetails?.description} />
    </>
  );
};

OrdersPage.Layout = Layout;
export default OrdersPage;
