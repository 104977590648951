import useSWR from 'swr';
import Api from '../../services/Api';
import { orders } from '../../ApiEndpoints';
import { getCustomerId } from '../../utils/auth';
import { useEffect, useState } from 'react';

const initialFilters = {
  page: 1,
  limit: 10,
};

function useGetOrders() {
  const [filters, setFilters] = useState(initialFilters);

  const getKey = (filters) => {
    // Below url has type query params that is only for SWR to identify url uniquely.
    // That is not a valid query params for Bigcommerce.
    const id = getCustomerId();
    if (id) {
      return `${orders}?customer_id=${id}&page=${filters.page}&limit=${filters.limit}`;
    }
    return `${orders}?customer_id=128&page=${filters.page}&limit=${filters.limit}`;
  };

  const { data, error, mutate } = useSWR(() => getKey(filters), getOrders);
  const isLoading = (!data && !error) || false;

  const onPageChange = (page_number) => {
    setFilters((prevState) => ({
      ...prevState,
      page: page_number,
    }));
  };

  useEffect(() => {
    mutate();
  }, [filters, mutate]);

  return {
    data: data?.data || [],
    pagination: data?.meta?.pagination || {},
    isLoading,
    isNextPage: data?.next_page,
    onPageChange,
    filters,
  };
}

async function getOrders(url) {
  const response = await Api.get(url);
  return response?.data || [];
}

export default useGetOrders;
